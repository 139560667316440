import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { SelectSnapshot } from '@ngxs-labs/select-snapshot'
import { finalize } from 'rxjs/operators'

import { SelectedGroup } from '../../../../../core/interfaces/organization/selected-group'
import { PaidStatus } from '../enums/paid-status'
import { DugnadTypes } from '../enums/dugnad-types'
import { ReminderType } from '../enums/reminder-type'
import { OrderStatus } from '../interfaces/order-status'
import { DugnadsService } from './../../../../../core/api/dugnads.service'
import { SnackbarService } from './../../../../../shared/components/snackbar/snackbar.service'
import { GroupSelectorState } from './../../../../../shared/modules/group-selector/states/group-selector.state'
import { SharedModule } from './../../../../../shared/modules/shared.module'
import { SMSStatus } from '../../../../../core/enums/organization/sms-status'
import { Modal } from '../../../../../core/classes/global/modal'
import { Constants } from '../../../../../constants/constants'
// import { SMSService } from '../../../../../core/api/sms.service'
import { DashboardState } from '../../../states/dashboard.state'
import { Organization } from '../../../../../core/interfaces/organization/organization'

@Component({
  selector: 'app-new-dugnad-reminder',
  templateUrl: './new-dugnad-reminder.component.html',
  styleUrls: ['./new-dugnad-reminder.component.scss'],
  standalone: true,
  imports: [SharedModule],
  animations: []
})
export class NewDugnadReminderComponent /* implements OnInit */ {
  @SelectSnapshot(GroupSelectorState.selectedGroup) selectedGroup: SelectedGroup
  @SelectSnapshot(DashboardState.organization) organization: Organization

  @Input() name: string
  @Input() dugnadType: DugnadTypes
  @Input() orderId: number
  @Input() isConfirmed: boolean
  @Input() orderStatus: OrderStatus
  @Input() paidStatus: PaidStatus

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>()

  loading: boolean = false
  reminderTypeField: FormControl<ReminderType> = new FormControl()
  notifyBySMSField: FormControl = new FormControl(false)
  messageField: FormControl = new FormControl('', Validators.maxLength(Constants.maxCharactersInPushNotification))
  smsStatus: SMSStatus | null = null
  notEnoughCreditsModal: Modal = new Modal()

  get cost(): number {
    return 1 + Math.ceil(this.messageField.value.length / Constants.smsCharactersPer1Credit)
  }

  get Constants() {
    return Constants
  }

  get SMSStatus() {
    return SMSStatus
  }

  get DugnadTypes() {
    return DugnadTypes
  }

  get ReminderType() {
    return ReminderType
  }

  get OrderStatus() {
    return OrderStatus
  }

  get PaidStatus() {
    return PaidStatus
  }

  constructor(
    private translate: TranslateService,
    // private smsService: SMSService,
    private dugnadsService: DugnadsService,
    private snackbarService: SnackbarService
  ) {}

  // ngOnInit() {
  //   this.loading = true
  //   this.smsService
  //     .getSMSStatusForPaymentReminders(this.organization.id)
  //     .pipe(finalize(() => (this.loading = false)))
  //     .subscribe((response) => {
  //       this.smsStatus = response.manual_dugnad_payment_reminder
  //       if (this.smsStatus === SMSStatus.NotEnoughCredits) {
  //         this.notifyBySMSField.disable()
  //       }
  //     })
  // }

  sendReminder(): void {
    this.loading = true

    const payload: { reminder_type: ReminderType; message: string; send_sms_notification?: boolean } = {
      reminder_type: this.reminderTypeField.value,
      message: this.messageField.value
    }

    // if (this.smsStatus === SMSStatus.Allowed && this.reminderTypeField.value === ReminderType.Payment) {
    //   payload.send_sms_notification = this.notifyBySMSField.value
    // }

    this.dugnadsService
      .sendReminder(this.orderId, this.selectedGroup.id, payload)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: () => {
          this.close.emit(true)
          this.snackbarService.success(this.translate.instant('economy.new_reminder_success', { name: this.name }))
        }
        // error: (e) => {
        //   if (e.error.code === 'sms__not_enough_credits') {
        //     this.notEnoughCreditsModal.open()
        //     this.smsStatus = SMSStatus.NotEnoughCredits
        //     this.notifyBySMSField.patchValue(false)
        //     this.notifyBySMSField.disable()
        //   }
        // }
      })
  }

  onClose(): void {
    this.close.emit(false)
  }
}
